<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? vars.textColor : 'var(--c-text-secondary)'}`"
  >
    <div class="tw-grid tw-w-full lg:tw-grid-cols-12" style="color: var(--c-text-primary)">
      <div
        :class="`${vars.isReversedBool && 'tw-order-3'}`"
        class="tw-col-span-12 tw-flex tw-w-full tw-flex-col tw-gap-5 lg:tw-col-span-5 lg:tw-self-start"
      >
        <h2>Fee Calculator</h2>
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.titleText"
          class="tw-w-full tw-rounded-xl tw-object-cover"
          :class="{
            'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
          }"
        />
        <iframe
          v-if="vars.videoUrl"
          class="tw-aspect-[16/9] tw-w-full tw-rounded-xl tw-object-cover"
          :src="vars.videoUrl"
          style="border-radius: var(--rounded)"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          :class="`n-${button.type} tw-mb-5 tw-self-start`"
          :to="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
      <div :class="`${vars.isReversedBool && 'tw-order-2'} tw-col-span-1 tw-hidden lg:tw-block`"></div>

      <div class="tw-col-span-12 tw-space-y-5 lg:tw-col-span-6">
        <h2 v-if="vars.mainTitleText">{{ vars.mainTitleText }}</h2>
        <div v-if="vars.descriptionText">
          <p>{{ vars.descriptionText }}</p>
        </div>
        <h4>{{ vars.subTitleText }}</h4>
        <div class="tw-space-y-1">
          <label for="fee-calculator">Asking Price</label>
          <div>
            <input ref="priceInput" id="fee-calculator" type="number" class="fee-input" />
          </div>
        </div>
        <button
          @click="calculate"
          :class="`n-${vars.calculateButtonTypeText} tw-max-w-full !tw-whitespace-normal tw-break-all`"
        >
          {{ vars.calculateButtonLabelText }}
        </button>
        <h4>Our Result</h4>
        <div class="tw-space-y-1">
          <p>{{ vars.resultLabelText }}</p>
          <button
            :class="`n-${vars.calculateButtonTypeText}`"
            class="tw-pointer-events-none tw-flex tw-items-center"
            style="font-family: var(--f-secondary) !important"
          >
            <h4>£ {{ result }}</h4>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      result: 0,
    };
  },
  methods: {
    calculate() {
      const price = Number(this.$refs.priceInput.value);

      const achievedUs = (price * 101.29) / 100;
      const achievedOther = (price * 96.3) / 100;
      const feeUs = (achievedUs * 1.8) / 100;
      const feeOther = (achievedOther * 1) / 100;
      const achievedAfterFeeUs = achievedUs - feeUs;
      const achievedAfterFeeOther = achievedOther - feeOther;

      this.result = Math.round(achievedAfterFeeUs - achievedAfterFeeOther);
    },
  },
  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
