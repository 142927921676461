<template>
  <section
    class="n-section-primary tw-overflow-x-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div class="tw-w-full tw-text-center">
      <h3 class="tw-mb-8" v-motion :initial="{ opacity: 0, y: 20 }" :enter="{ opacity: 1, y: 0 }">
        {{ vars.titleText }}
      </h3>

      <!-- Mobile Slider -->
      <div class="tw-relative tw-w-full lg:tw-hidden">
        <Swiper
          v-if="isMobile"
          :modules="[SwiperPagination]"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
          }"
          class="tw-w-full lg:!tw-hidden"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <SwiperSlide
            v-for="(item, index) in cards"
            :key="`location-${index}`"
            class="!tw-flex !tw-h-auto tw-items-center tw-justify-center"
          >
            <div
              class="tw-relative tw-bg-transparent"
              v-motion
              :initial="{ opacity: 0, scale: 0.9 }"
              :enter="{
                opacity: 1,
                scale: 1,
                transition: {
                  delay: index * 200,
                },
              }"
            >
              <nuxt-link :to="item.link" :external="true" style="all: unset !important; border-radius: var(--rounded)">
                <NuxtImg
                  :src="item.photo"
                  class="tw-inset-0 tw-h-full tw-min-h-[320px] tw-w-full"
                  style="border-radius: var(--rounded)"
                  loading="lazy"
                />
                <div
                  class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-center tw-rounded-lg tw-bg-black/20 tw-pb-4"
                  style="border-radius: var(--rounded)"
                >
                  <div class="tw-bg-white tw-p-2" style="border-radius: var(--rounded)">
                    <h5 class="!tw-text-xl">{{ item.title }}</h5>
                  </div>
                  <p v-if="vars.isShowPropertyCounterBool" class="tw-mb-2" style="color: var(--c-text-secondary)">
                    {{ parseFormV2(item.item).property_count }} properties
                  </p>
                </div>
              </nuxt-link>
            </div>
          </SwiperSlide>
        </Swiper>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v3"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
      <!-- Desktop Grid View -->
      <div class="tw-hidden tw-w-full tw-grid-cols-3 tw-grid-rows-2 tw-gap-6 lg:tw-grid xl:tw-gap-10">
        <nuxt-link
          v-for="(item, index) in cards"
          :to="item.link"
          :external="true"
          :key="`location-${index}`"
          class="n-link tw-group tw-relative tw-max-h-96 tw-overflow-hidden tw-rounded-lg tw-duration-300 hover:tw-scale-95"
          :class="{ 'tw-col-span-2': item.wide }"
          v-motion
          :initial="{ opacity: 0, scale: 0.9 }"
          :enter="{
            opacity: 1,
            scale: 1,
            transition: {
              delay: index * 200,
            },
          }"
        >
          <NuxtImg
            :src="item.photo"
            class="tw-inset-0 tw-z-0 tw-h-full tw-w-full tw-duration-300 group-hover:tw-rotate-2 group-hover:tw-scale-105"
            loading="lazy"
            @load="onImageLoad(index)"
          />
          <div
            class="tw-absolute tw-inset-0 tw-flex tw-flex-col-reverse tw-items-start tw-rounded-lg tw-bg-black/20 tw-pl-4"
            :class="{ 'tw-opacity-0': !imageLoaded[index] }"
            :style="{ transition: 'opacity 0.3s' }"
          >
            <div class="tw-flex tw-items-center tw-gap-4 tw-pb-4">
              <div class="tw-bg-white tw-p-2" style="border-radius: var(--rounded)">
                <h5 class="!tw-text-xl">{{ item.title }}</h5>
              </div>
              <p v-if="vars.isShowPropertyCounterBool" style="color: var(--c-text-secondary)">
                {{ parseFormV2(item.item).property_count }} properties
              </p>
            </div>
          </div>
        </nuxt-link>
      </div>

      <div class="tw-mt-12">
        <nuxt-link :class="`n-${vars.buttonTypeText}`" :to="vars.buttonLinkUrl" :external="true">
          {{ vars.buttonLabelText }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWindowSize } from '@vueuse/core';

export default defineNuxtComponent({
  name: 'OurLocations',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
    };
  },

  mounted() {
    this.onSlideChange();
  },

  setup() {
    const { width } = useWindowSize();
    const isMobile = computed(() => width.value < 1024);
    const imageLoaded = ref(Array(6).fill(false));

    const onImageLoad = (index: number) => {
      imageLoaded.value[index] = true;
    };

    return {
      isMobile,
      imageLoaded,
      onImageLoad,
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  computed: {
    cards() {
      return this.groupedVariables.cards;
    },
  },
});
</script>
