<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div class="tw-flex tw-w-full tw-flex-col">
      <h2 class="tw-mb-6 md:tw-mb-10">{{ vars.titleText }}</h2>
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-4 md:tw-justify-start">
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'" :duration="400">
          <BranchCard
            v-for="(branch, index) in branches"
            :key="index"
            :branch="branch"
            class="tw-max-w-[500px] tw-flex-grow sm:tw-w-[48%] xl:tw-w-[32%]"
          />
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import BranchCard from './BranchCard.vue';

export default defineNuxtComponent({
  name: 'AllBranches',

  mixins: [ComponentMixin],

  components: {
    BranchCard,
  },

  computed: {
    branches() {
      return this.groupedVariables.branches;
    },
  },
});
</script>
