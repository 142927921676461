<template>
  <section v-if="variant === variants.v1" class="tw-h-[80vh]">
    <NuxtImg :src="vars.backgroundImage" class="tw-absolute tw-h-full tw-w-full tw-object-cover" />

    <div
      class="tw-relative tw-m-3 tw-flex tw-max-w-3xl tw-flex-col tw-justify-center tw-gap-5 tw-rounded-lg tw-px-4 tw-py-9 tw-text-start sm:tw-px-8 md:tw-pr-24 xl:tw-ml-10 xl:tw-mr-[40%]"
      style="color: var(--c-text-primary)"
      :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
    >
      <h3>{{ vars.titleText }}</h3>
      <p>
        {{ vars.descriptionText }}
      </p>
      <div class="tw-mt-2 tw-flex tw-flex-row tw-gap-3">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`card-btn-${index}`"
          :class="`n-${button.type}`"
          :href="button.link"
          :external="true"
        >
          {{ button.label }}
        </nuxt-link>
      </div>
    </div>
  </section>
  <section v-if="variant === variants.v2" class="tw-h-[80vh]">
    <NuxtImg :src="vars.backgroundImage" class="tw-absolute tw-h-full tw-w-full tw-object-cover" />
    <div
      class="tw-absolute tw-inset-0 tw-opacity-80"
      :style="`background-color: var(--c-${vars.backgroundLayerColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
    ></div>

    <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="600">
      <div
        class="tw-relative tw-m-3 tw-flex tw-max-w-3xl tw-flex-col tw-justify-center tw-gap-5 tw-rounded-lg tw-px-4 tw-py-9 tw-text-center"
        style="color: var(--c-text-primary)"
        :style="`background-color: var(--c-${vars.backgroundColor})`"
      >
        <h3>{{ vars.titleText }}</h3>
        <p class="tw-max-h-[34vh] tw-overflow-y-auto">
          {{ vars.descriptionText }}
        </p>
        <div class="tw-mt-2 tw-flex tw-flex-row tw-justify-center tw-gap-3">
          <nuxt-link
            v-for="(button, index) in buttons"
            :key="`card-btn-${index}`"
            :class="`n-${button.type}`"
            :href="button.link"
            :external="true"
          >
            {{ button.label }}
          </nuxt-link>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CardSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-secondary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
