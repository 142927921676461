<template>
  <lazy-library-container v-if="variant === variants.v1" class="tw-py-24">
    <div class="container text-center tw-mb-10">
      <lazy-library-title :text="vars.titleHtml" tag="h3"></lazy-library-title>
    </div>

    <div class="pxp-areas-carousel tw-mt-20 tw-px-0">
      <div class="pxp-areas-carousel-stage knowledge-hub-carousel-stage owl-carousel">
        <theme3-components-guide-box
          v-for="(item, index) in items"
          :key="`knowledge-hub-${key}-${index}`"
          class="pxp-areas-carousel-item"
          :item="item"
          :component="component"
          style-name="v1"
        >
        </theme3-components-guide-box>
      </div>
    </div>

    <div class="container">
      <div class="text-center mt-4 mt-md-5">
        <nuxt-link :to="vars.ctaUrl" style="color: var(--purple)" class="pxp-primary-cta text-uppercase pxp-animate">
          {{ vars.ctaText }}
        </nuxt-link>
      </div>
    </div>
  </lazy-library-container>
  <lazy-library-container v-if="variant === variants.v2" class="tw-py-24">
    <div class="container text-center tw-mb-10">
      <lazy-library-title :text="vars.titleHtml" tag="h3"></lazy-library-title>
    </div>

    <div class="pxp-areas-carousel tw-px-0">
      <div class="pxp-areas-carousel-stage knowledge-hub-carousel-stage owl-carousel knowledge-hub-variant-2">
        <div v-for="(item, index) in items" :key="`knowledge-hub-${key}-${index}`">
          <theme3-components-guide-box :item="item" :component="component" style-name="v2">
          </theme3-components-guide-box>
        </div>
      </div>
    </div>

    <div v-if="vars.ctaUrl && vars.ctaText" class="container">
      <div class="text-center mt-4 mt-md-5">
        <nuxt-link :to="vars.ctaUrl" style="color: var(--purple)" class="pxp-primary-cta text-uppercase pxp-animate">
          {{ vars.ctaText }}
        </nuxt-link>
      </div>
    </div>
  </lazy-library-container>
  <div v-if="variant === variants.v1Sidebar">
    <div>
      <lazy-library-title :text="vars.titleHtml" tag="h3"></lazy-library-title>
    </div>

    <div v-if="vars.ctaUrl && vars.ctaText" class="tw-mt-4">
      <nuxt-link :to="vars.ctaUrl" style="color: var(--purple)" class="pxp-primary-cta text-uppercase pxp-animate">
        {{ vars.ctaText }}
      </nuxt-link>
    </div>

    <div class="pxp-areas-carousel tw-mt-4 tw-px-0">
      <div class="pxp-areas-carousel-stage knowledge-hub-carousel-stage-sidebar owl-carousel">
        <theme3-components-guide-box
          v-for="(item, index) in items"
          :key="`knowledge-hub-${key}-${index}`"
          class="pxp-areas-carousel-item"
          :item="item"
          :component="component"
          style-name="v1"
        >
        </theme3-components-guide-box>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { jQuery } from 'public/theme3/js/custom';
import { produceKey } from '~/server/core/helpers';

export default defineNuxtComponent({
  name: 'Theme4KnowledgeHubBox',

  mixins: [ComponentMixin],

  data() {
    return {
      key: produceKey(),

      variants: {
        v1: 'v1',
        v2: 'v2',
        v1Sidebar: 'v1Sidebar',
      },
    };
  },

  computed: {
    items() {
      return this.groupedVariables.items;
    },
  },

  mounted() {
    if (this.variant === this.variants.v1Sidebar) {
      jQuery.knowledgeHubSliderSidebar();
    } else {
      jQuery.knowledgeHubSlider();
    }
  },
});
</script>

<style scoped>
::v-deep(.pxp-areas-carousel-right-arrow) {
  top: 40% !important;
}

::v-deep(.pxp-areas-carousel-left-arrow) {
  top: 40% !important;
}

::v-deep(.knowledge-hub-variant-2 .pxp-areas-carousel-right-arrow) {
  top: 50% !important;
}

::v-deep(.knowledge-hub-variant-2 .pxp-areas-carousel-left-arrow) {
  top: 50% !important;
}

.knowledge-hub-variant-2 .pxp-primary-cta:after {
  border-color: var(--white);
}

.knowledge-hub-variant-2 .pxp-prop-card-1-fig:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 50% black overlay */
}

.knowledge-hub-variant-2 .pxp-prop-card-1:hover .pxp-prop-card-1-fig:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 205, 212, 0.3);
}
</style>
