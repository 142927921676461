<template>
  <section
    class="tw-items-center tw-justify-center tw-py-24"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'} `"
  >
    <MotionGroup :preset="vars.animationText ? vars.animationText : 'slideVisibleLeft'" :duration="600">
      <h2 class="tw-text-3xl tw-font-bold lg:tw-text-4xl" style="line-height: 180%">
        {{ vars.titleText }}
      </h2>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TextBanner',

  mixins: [ComponentMixin],
});
</script>
