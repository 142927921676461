<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div>
      <h1 class="tw-mb-14 tw-self-start xl:tw-mb-20">
        {{ vars.mainTitleText }}
      </h1>
      <div class="tw-grid tw-max-w-xl tw-gap-3 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-items-center xl:tw-gap-x-24">
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.mainTitleText"
          class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5"
          :class="{
            'xl:tw-order-last': vars.isReversedBool,
          }"
        />
        <div
          v-if="vars.videoUrl"
          class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5"
          :class="{
            'xl:tw-order-last': vars.isReversedBool,
          }"
        >
          <library-video-embed
            :video-url="vars.videoUrl"
            :title="vars.mainTitleText"
            :is-popup="true"
            :cover-photo="vars.photoImage"
          />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4 xl:tw-col-span-7">
          <h3>{{ vars.titleText }}</h3>
          <p v-for="(item, index) in descriptions" :key="`content-section-description-${index}`">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentSection',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
