<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <h2 class="tw-mb-12 tw-text-center">{{ vars.titleText }}</h2>
    <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-10">
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="600">
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="false"
          :slides-per-view="1"
          :space-between="20"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }"
          @swiper="onSwiper"
          class="tw-w-full"
        >
          <SwiperSlide v-for="(value, index) in values" :key="index">
            <div
              class="value tw-flex tw-h-[572px] tw-flex-col tw-items-start tw-justify-start tw-gap-3 tw-rounded-[var(--rounded)] tw-bg-white tw-p-6 tw-text-start md:tw-h-[520px] xl:tw-h-[480px]"
            >
              <NuxtImg :src="value.icon" class="tw-mb-3 tw-w-24" />

              <div>
                <h4 class="tw-line-clamp-2 tw-h-[65px]">{{ value.title }}</h4>
                <p class="tw-mt-2 md:tw-max-h-[290px] md:tw-overflow-auto xl:tw-max-h-[240px]">
                  {{ value.description }}
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </MotionGroup>

      <div class="lg:tw-hidden">
        <LibrarySliderPagination
          :swiper="swiper"
          :can-paginate-back="canPaginateBack"
          variant="v3"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: null,
      canPaginateNext: null,
      isAllVisible: null,
    };
  },
  computed: {
    values() {
      return this.groupedVariables.values;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-secondary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}

.value {
  overflow: hidden;
}

.value::before,
.value::after {
  content: '';
  height: 14px;
  width: 100px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.value::before {
  content: '';
  right: -100px;
  top: 0;
  border-top: 3px solid var(--c-secondary);
  border-top-right-radius: var(--rounded);
}

.value::after {
  content: '';
  left: -100px;
  bottom: 0;
  border-bottom: 3px solid var(--c-secondary);
  border-bottom-left-radius: var(--rounded);
}

.value:hover::before {
  right: 0;
  opacity: 1;
}
.value:hover::after {
  left: 0;
  opacity: 1;
}
</style>
