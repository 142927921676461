<template>
  <section
    class="n-section-primary tw-overflow-y-hidden"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div
      v-if="variants.v1 === variant"
      class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-4 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20"
    >
      <NuxtImg
        v-if="vars.photoImage && !vars.videoUrl"
        :src="vars.photoImage"
        :alt="vars.titleText"
        class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      />
      <div
        v-if="vars.videoUrl"
        class="tw-w-full tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
        :class="{
          'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
        }"
      >
        <library-video-embed
          :video-url="vars.videoUrl"
          :title="vars.titleText"
          :is-popup="true"
          :cover-photo="vars.photoImage"
        />
      </div>
      <h3 class="xl:tw-col-span-7 xl:tw-row-start-1 xl:tw-self-end">
        {{ vars.titleText }}
      </h3>
      <div class="tw-flex tw-flex-col xl:tw-col-span-7 xl:tw-row-start-2 xl:tw-self-start">
        <p
          class="xl:tw-whitespace-pre-line"
          v-for="(item, index) in descriptions"
          :key="`why-box-description-${index}`"
        >
          {{ item.description }}
        </p>
        <div class="tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2">
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns1"
              v-for="(feature, index) in featuresColumns1"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-secondary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-y-2">
            <div
              v-if="featuresColumns2"
              v-for="(feature, index) in featuresColumns2"
              :key="index"
              class="tw-flex tw-items-center tw-gap-3"
            >
              <svg
                class="tw-shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="var(--c-secondary)"
                />
              </svg>
              <h6>
                {{ feature.title }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LibraryPopupsVideoPopup
      v-if="vars.isPopupBool && vars.videoUrl"
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
      :portrait="vars.isVerticalBool"
    />

    <MotionGroup preset="slideVisibleRight" :duration="600">
      <div
        v-if="variants.v2 === variant"
        class="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-4 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-gap-x-20"
      >
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.titleText"
          class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
          }"
        />
        <div
          v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
          class="tw-relative tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:!tw-col-span-4 xl:!tw-col-start-9': vars.isVerticalBool,
            'xl:!tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
          }"
          @click="isVisible = true"
        >
          <LibraryImage
            :src="vars.photoImage"
            :alt="vars.titleText"
            class="tw-w-full tw-cursor-pointer"
            :w-ratio="vars.isVerticalBool ? 2 : 645"
            :h-ratio="vars.isVerticalBool ? 3 : 430"
            style="border-radius: var(--rounded-sm)"
          />
          <button class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
            <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="white" />
              <polygon points="65,45 115,81 65,117" fill="black" />
            </svg>
          </button>
        </div>
        <div
          v-if="!vars.isPopupBool && vars.videoUrl"
          class="tw-w-full tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:tw-col-start-1 xl:tw-row-start-1': vars.isReversedBool,
            'xl:!tw-col-span-4 xl:!tw-col-start-9': vars.isVerticalBool,
            'xl:!tw-col-start-1': vars.isReversedBool && vars.isVerticalBool,
          }"
        >
          <iframe
            v-if="!vars.isPopupBool && vars.videoUrl"
            class="tw-w-full tw-rounded-xl tw-object-cover"
            :src="vars.videoUrl"
            style="border-radius: var(--rounded)"
            frameborder="0"
            allowfullscreen
            :style="vars.isVerticalBool ? 'aspect-ratio: 9/16;' : 'aspect-ratio: 16/9;'"
          ></iframe>
        </div>
        <h3 class="xl:tw-col-span-7 xl:tw-row-start-1 xl:tw-self-end">
          {{ vars.titleText }}
        </h3>
        <div class="tw-flex tw-flex-col xl:tw-col-span-7 xl:tw-row-start-2 xl:tw-self-start">
          <p
            class="xl:tw-whitespace-pre-line"
            v-for="(item, index) in descriptions"
            :key="`why-box-description-${index}`"
          >
            {{ item.description }}
          </p>
          <div class="tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-5 sm:tw-mr-auto sm:tw-grid-cols-2">
            <div class="tw-flex tw-flex-col tw-gap-y-2">
              <div
                v-if="featuresColumns1"
                v-for="(feature, index) in featuresColumns1"
                :key="index"
                class="tw-flex tw-items-center tw-gap-3"
              >
                <svg
                  class="tw-shrink-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                    fill="var(--c-secondary)"
                  />
                </svg>
                <h6>{{ feature.title }}</h6>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-y-2">
              <div
                v-if="featuresColumns2"
                v-for="(feature, index) in featuresColumns2"
                :key="index"
                class="tw-flex tw-items-center tw-gap-3"
              >
                <svg
                  class="tw-shrink-0"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                    fill="var(--c-secondary)"
                  />
                </svg>
                <h6>{{ feature.title }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MotionGroup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },
    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
