<template>
  <section
    class="tw-h-screen"
    :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-secondary)'}`"
  >
    <video
      class="tw-absolute tw-left-0 tw-top-0 tw-hidden tw-h-full tw-w-full tw-object-cover lg:tw-block"
      autoplay
      loop
      muted
      playsinline
    >
      <source :src="vars.backgroundVideo" type="video/mp4" />
    </video>
    <video
      class="tw-absolute tw-left-0 tw-top-0 tw-block tw-h-full tw-w-full tw-object-cover lg:tw-hidden"
      autoplay
      loop
      muted
      playsinline
    >
      <source :src="vars.mobileBackgroundVideo" type="video/mp4" />
    </video>
    <div v-if="variants.v1 === variant" class="tw-h-full tw-w-full tw-text-center">
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>
      <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-items-end tw-justify-center">
        <div
          class="tw-z-100 tw-mx-2 tw-mb-6 tw-flex tw-flex-col tw-gap-2 tw-text-center tw-text-white md:tw-mx-12 md:tw-mb-8 xl:tw-mb-24 xl:tw-gap-6"
        >
          <h2>
            {{ vars.titleText }}
          </h2>
          <h5 class="tw-mt-4 tw-text-white">
            {{ vars.descriptionText }}
          </h5>
          <div class="tw-m-6 tw-flex tw-items-center tw-justify-center tw-gap-3 lg:tw-gap-5">
            <nuxt-link
              :external="true"
              :to="button.link"
              v-for="(button, index) in buttons"
              :key="index"
              :class="`n-${button.type}`"
            >
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="variants.v2 === variant" class="tw-h-full tw-w-full tw-text-center">
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-30"></div>
      <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-w-full tw-items-end tw-justify-start">
        <div
          class="tw-z-100 tw-mx-2 tw-mb-4 tw-flex tw-w-full tw-flex-col tw-gap-2 tw-text-left tw-text-white md:tw-mx-12 md:tw-mb-8 xl:tw-mb-24 xl:tw-gap-6"
        >
          <div v-html="vars.titleHtml"></div>

          <small class="tw-mb-4 tw-max-w-2xl tw-text-white">
            {{ vars.descriptionText }}
          </small>
          <div
            class="tw-flex tw-justify-start tw-gap-3 tw-text-center max-sm:tw-flex-col sm:tw-items-start lg:tw-gap-5"
          >
            <nuxt-link
              :external="true"
              :to="button.link"
              v-for="(button, index) in buttons"
              :key="index"
              :class="`n-${button.type}`"
            >
              {{ button.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HeroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
